import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion"

export default function Procedures({ data }) {
  const procedures = data.allFile.edges
  const bannerHeader = data.bannerHeader.childImageSharp.fluid
  const bannerShadow = data.bannerShadow.childImageSharp.fluid
  return (
    <Layout>
      <div className='background-image'>
        <Image fluid={bannerHeader} className='background-image-medium banner-header-background' />
        <h1 className='banner-header-text'>Oferta Premium 2023</h1>
        <Image
          fluid={bannerShadow}
          className='background-rounded-shadow'
          style={{ position: "absolute" }}
          loading="eager"
        />
      </div>
      <div className='content-container'>
        <Accordion allowZeroExpanded>
          {procedures.map((edge, i) => {
            const procedure = edge.node.childProceduresJson
            return (
              <AccordionItem key={`content_category_${i}`}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <Image
                      fluid={procedure.banner.childImageSharp.fluid}
                      style={{ position: "absolute", width: "100%", height: "100%" }}
                    />
                    <h2>{procedure.title}</h2>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    {procedure.pList.map((child, j) => (
                      <li key={`content_item_${j}`}>
                        <Link to={`/procedures/${procedure.slug}/${child.slug}`} className='accordion-list-item'>
                          <div>{child.title}</div>
                          <div className='accordion-list-item__right-side'>
                            <div>Cena od<span style={{fontSize: '17px', paddingLeft: '5px'}}>{child.price_first_time} {child.unit}</span></div>
                            <div>Czas trwania zabiegu<mark style={{fontSize: '14px', paddingLeft: '5px'}}>{child.duration_first_time}</mark></div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "procedures"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          childProceduresJson {
            slug
            title
            banner {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            pList {
              slug
              title
              price_first_time
              unit
              duration_first_time
            }
          }
        }
      }
    }
    bannerHeader: file(relativePath: {eq: "procedures/images/banner_header.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
